/* eslint-disable no-unused-vars */
import React from "react";
import { isMobile } from "react-device-detect";

class Sandbox2 extends React.Component {
  /**
   * constructor
   *
   * @object  @props  parent props
   * @object  @state  component state
   */
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
  }
  getCategories(inputArray) {
    var randomCategories = [];

    while (randomCategories.length < 5) {
      var r = Math.floor(Math.random() * inputArray.length);
      if (!randomCategories.includes(inputArray[r].name))
        inputArray[r].name.replace("Entertainment: ", "");
      randomCategories.push(
        inputArray[r].name
          .replace("Entertainment: ", "")
          .replace("Science: ", "")
      );
    }
    const { error, isLoaded, items, categoryLenth } = this.state;
    return randomCategories;
  }
  detectDevice() {
    var a = isMobile ? "Mobile" : "Desktop";
    alert(a);
  }
  async getQuestions(category) {
    const responseObjects = [];

    fetch(
      //change baack to 5 !!!!!!!!!!!!!!!!!!!!!!
      "https://opentdb.com/api.php?amount=1&category=9&difficulty=easy&type=multiple"
    )
      .then((res) => res.json())
      .then((data) => {
        data.results.forEach(
          (item) =>
            alert(
              "Category: " +
                item.category +
                "\n" +
                item.question +
                "\n" +
                item.incorrect_answers + //.replace(",", ", ") +
                "," +
                item.correct_answer
            )
          // eslint-disable-next-line no-undef
          /*
            responseObjects.push([
            item.category,
            item.difficulty,
            item.question,
            item.correct_answer,
            item.incorrect_answer,
          ])
          */
        );
        //alert(responseObjects[0].question);
      })
      .catch(console.log);

    alert("Alert Title", "My Alert Msg", [
      {
        text: "Cancel",
        onPress: () => console.log("Cancel Pressed"),
        style: "cancel",
      },
      { text: "OK", onPress: () => console.log("OK Pressed") },
    ]);

    return responseObjects;
  }

  async getCategoriesAndQuestions(inputArray) {
    var categories = await this.getCategories(inputArray);
    const questionObjects = [];
    //https://opentdb.com/api.php?amount=10&category=9&difficulty=easy&type=multiple
    categories.forEach((category) => {
      const questions = this.getQuestions(category);
      questionObjects.push(questions);
    });
    questionObjects.forEach((qo) => {
      //alert(qo);
    });
  }

  componentDidMount() {
    fetch("https://opentdb.com/api_category.php")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.trivia_categories,
            //categoryLenth: result.trivia_categories.length - 1,
            //displayIndexes: [],
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }
  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <div>
            <button onClick={() => this.getCategoriesAndQuestions(items)}>
              Test Something
            </button>
            &nbsp;&nbsp;&nbsp;
            <button onClick={() => this.detectDevice()}>Detect Device</button>
          </div>
          <div>
            <ul>
              {items.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
  }
}

export default Sandbox2;
