/* eslint-disable react/require-render-return */
/* eslint-disable react/prop-types */
import React from "react";
import categories from "./Category.js";

class Headers extends React.Component {
  getCategories(inputArray) {
    var randomCategories = [];

    while (randomCategories.length < 4) {
      var r = Math.floor(Math.random() * inputArray.length);
      randomCategories.push({ id: inputArray[r].id, name: inputArray[r].name });
      //if (!randomCategories.includes(inputArray[r]))
    }
    return randomCategories;
  }
  async getQuestions(input) {
    fetch(
      `https://opentdb.com/api.php?amount=1&category=${input}&difficulty=easy`
    )
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then(function (data) {
        // Store the post data to a variable
        // Fetch another API
        return fetch(
          `https://opentdb.com/api.php?amount=1&category=${data}&difficulty=medium`
        );
      })
      .then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          return Promise.reject(response);
        }
      })
      .then(function (userData) {
        return fetch(
          `https://opentdb.com/api.php?amount=1&category=${userData}&difficulty=hard`
        );
      })
      .catch(function (error) {
        console.warn(error);
      });
  }
  render() {
    let style = {
        width: this.props.headerWidth,
      },
      headers = [];

    var randomCategories = [];
    categories.trivia_categories.forEach((element) =>
      randomCategories.push({ id: element.id, name: element.name })
    );

    //https://opentdb.com/api.php?amount=1&category=9&difficulty=easy&type=multiple
    //https://opentdb.com/api.php?amount=1&category=9&difficulty=easy&type=boolean
    //https://opentdb.com/api.php?amount=1&category=9&difficulty=easy

    // eslint-disable-next-line no-unused-vars
    var a = this.getCategories(randomCategories);
    a.forEach((category, index) =>
      headers.push(
        <span className="header" style={style} key={index}>
          {category.name.replace("Entertainment: ", "")}
        </span>
      )
    );
    /*
    this.props.data.forEach((category, index) =>
      headers.push(
        <span className="header" style={style} key={index}>
          {category.category}
        </span>
      )
    );
*/
    var test = [];
    a.forEach((category) => test.push(category.id));
    /*
    const borderCountr = await Promise.all(
      test.map(async (record) => {
        const response = await fetch(
          `https://opentdb.com/api.php?amount=1&category=${record}&difficulty=easy`,
          `https://opentdb.com/api.php?amount=1&category=${record}&difficulty=easy`,
          `https://opentdb.com/api.php?amount=1&category=${record}&difficulty=easy`
        );
        return await response.json();
      })
    );
    */
    // eslint-disable-next-line no-unused-vars
    //var b = this.getQuestions(1);
    //console.log(borderCountr[0], borderCountr[1]);

    //const response = await fetch("https://opentdb.com/api.php?amount=1");
    //const content = await response.json();
    //alert(test[0]);
    return <div className="headers">{headers}</div>;
  }
}

export default Headers;
