/* eslint-disable no-unused-vars */
import React from "react";
import { isMobile } from "react-device-detect";

class Question extends React.Component {
  /**
   * constructor
   *
   * @object  @props  parent props
   * @object  @state  component state
   */
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      isLoaded: false,
      items: [],
    };
  }

  getQuestions() {
    this.componentDidMount();
    const { error, isLoaded, items } = this.state;
    return items;
  }

  getrandQuestions() {
    var randomCategories = [];

    const { items } = this.state;
    while (randomCategories.length < 5) {
      var r = Math.floor(Math.random() * items.length);
      if (!randomCategories.includes(items[r].name))
        items[r].name.replace("Entertainment: ", "");
      randomCategories.push(
        items[r].name.replace("Entertainment: ", "").replace("Science: ", "")
      );
    }
    var a = isMobile ? "Mobile" : "Desktop";
    alert(randomCategories);
    return randomCategories;
    //alert(a);
  }

  componentDidMount() {
    fetch("https://opentdb.com/api_category.php")
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.trivia_categories,
          });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    const { error, isLoaded, items } = this.state;
    if (error) {
      return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
      return <div>Loading...</div>;
    } else {
      return (
        <div>
          <div>
            <ul>
              {items.map((item) => (
                <li key={item.id}>{item.name}</li>
              ))}
            </ul>
          </div>
        </div>
      );
    }
  }
}

export default Question;
