import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
// import Weekend from "@material-ui/icons/Weekend";
import Home from "@material-ui/icons/Home";
//import BugReport from "@material-ui/icons/BugReport";
//import Code from "@material-ui/icons/Code";
//import Cloud from "@material-ui/icons/Cloud";
import FormatQuote from "@material-ui/icons/FormatQuote";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
//import Timeline from "components/Timeline/Timeline.js";
//import CustomTabs from "components/CustomTabs/CustomTabs.js";
//import Tasks from "components/Tasks/Tasks.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardText from "components/Card/CardText.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

//import { widgetStories, bugs, website, server } from "variables/general.js";

import image from "assets/img/faces/card-profile1-square.jpg";

import {
  cardTitle,
  roseColor,
} from "assets/jss/material-dashboard-pro-react.js";

const styles = {
  cardTitle,
  cardTitleWhite: {
    ...cardTitle,
    color: "#FFFFFF",
    marginTop: "0",
  },
  cardCategoryWhite: {
    margin: "0",
    color: "rgba(255, 255, 255, 0.8)",
    fontSize: ".875rem",
  },
  cardCategory: {
    color: "#999999",
    marginTop: "10px",
  },
  icon: {
    color: "#333333",
    margin: "10px auto 0",
    width: "130px",
    height: "130px",
    border: "1px solid #E5E5E5",
    borderRadius: "50%",
    lineHeight: "174px",
    "& svg": {
      width: "55px",
      height: "55px",
    },
    "& .fab,& .fas,& .far,& .fal,& .material-icons": {
      width: "55px",
      fontSize: "55px",
    },
  },
  iconRose: {
    color: roseColor,
  },
  marginTop30: {
    marginTop: "30px",
  },
  testimonialIcon: {
    marginTop: "30px",
    "& svg": {
      width: "40px",
      height: "40px",
    },
  },
  cardTestimonialDescription: {
    fontStyle: "italic",
    color: "#999999",
  },
};

const useStyles = makeStyles(styles);

export default function Widgets() {
  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={14} md={10}>
          <Card>
            <CardHeader color="warning" text>
              <CardText color="warning">
                <h4 className={classes.cardTitleWhite}>Reading List</h4>
                <h4 className={classes.cardCategoryWhite}>
                  New employees on 15th September, 2016
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Table
                hover
                tableHeaderColor="warning"
                tableHead={["Name", "Summary", "Purchase"]}
                flex={true}
                tableData={[
                  [
                    "Creativity, Inc.",
                    "Creativity is an instruction for instilling inspiration into employees, managers and bosses, by revealing the hidden forces that get in the way, based on over 30 years of experience of the president of Pixar, Ed Catmull.",
                    "https://amz.run/4v2Z",
                  ],
                  [
                    "The Everything Store",
                    "The Everything Store is the closest biographical documentation of the unprecedented rise of Amazon as an online retail store with an almost infinite amount of choice, based on over 300 interviews with current and former Amazon employees and executives, family members of the founder and the hard facts available to the public.",
                    "https://amz.run/4v2a",
                  ],
                  [
                    "Read Speeder",
                    "Real speed reading requires 'speed comprehension'. Learn how to comprehend faster by learning to read whole phrases at a time. Free online course.",
                    "https://readspeeder.com",
                  ],
                  [
                    "Clean Code",
                    "Code is clean if it can be understood easily – by everyone on the team. Clean code can be read and enhanced by a developer other than its original author. With understandability comes readability, changeability, extensibility and maintainability.",
                    "https://amz.run/4v50",
                  ],
                  [
                    "Clean Architecture",
                    "Practical Software Architecture Solutions from the Legendary Robert C. Martin (“Uncle Bob”) By applying universal rules of software architecture, you can dramatically improve developer productivity throughout the life of any software system.",
                    "https://amz.run/4v4z",
                  ],
                  [
                    "Head First Design Patterns",
                    "At any given moment, someone struggles with the same software design problems you have. And, chances are, someone else has already solved your problem. This edition of Head First Design Patterns—now updated for Java 8—shows you the tried-and-true, road-tested patterns used by developers to create functional, elegant, reusable, and flexible software. By the time you finish this book, you’ll be able to take advantage of the best design practices and experiences of those who have fought the beast of software design and triumphed.",
                    "https://amz.run/4v4x",
                  ],
                  [
                    "Domain-Driven Design",
                    "Domain-driven design is the concept that the structure and language of software code should match the business domain. For example, if a software processes loan applications, it might have classes such as LoanApplication and Customer, and methods such as AcceptOffer and Withdraw.",
                    "https://amz.run/4v4w",
                  ],
                  [
                    "Code Complete",
                    "Code Complete is a software development book, written by Steve McConnell and published in 1993 by Microsoft Press, encouraging developers to continue past code-and-fix programming and the big design up front and waterfall models.",
                    "https://amz.run/4v4v",
                  ],
                  [
                    "The Pragmatic Programmer",
                    "The Pragmatic Programmer: From Journeyman to Master is a book about computer programming and software engineering, written by Andrew Hunt and David Thomas and published in October 1999. It is used as a textbook in related university courses.",
                    "https://amz.run/4v4t",
                  ],
                  [
                    "Algorithms",
                    "This book surveys the most important computer algorithms currently in use and provides a full treatment of data structures and algorithms for sorting, searching, graph processing, and string processing -- including fifty algorithms every programmer should know.",
                    "https://amz.run/4v4q",
                  ],
                  [
                    "Working Effectively with Legacy Code",
                    "This book provides programmers with the ability to cost effectively handlecommon legacy code problems without having to go through the hugelyexpensive task of rewriting all existing code. It describes a series of practicalstrategies that developers can employ to bring their existing softwareapplications under control.",
                    "https://amz.run/4v4n",
                  ],
                  [
                    "Refactoring",
                    "The book Refactoring 2nd edition by Martin Fowler provides detailed descriptions of refactorings with a motivation for doing them, the mechanics, and an example. It teaches you how to improve the design and quality of code in small steps without changing external behavior.",
                    "https://amz.run/4v4i",
                  ],
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={14} md={10}>
          <Card>
            <CardHeader color="warning" text>
              <CardText color="warning">
                <h4 className={classes.cardTitleWhite}>
                  Snowflake & DataBricks
                </h4>
                <h4 className={classes.cardCategoryWhite}>
                  New employees on 15th September, 2016
                </h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <Table
                hover
                tableHeaderColor="warning"
                tableHead={["Tags", "Link"]}
                tableData={[
                  [
                    "BEST PRACTICES FOR OPTIMIZING YOUR DBT AND SNOWFLAKE DEPLOYMENT",
                    "https://www.snowflake.com/wp-content/uploads/2021/10/Best-Practices-for-Optimizing-Your-dbt-and-Snowflake-Deployment.pdf",
                  ],
                  [
                    "Snowflake - Medium Articles",
                    "https://medium.com/snowflake",
                  ],
                  [
                    "Performance Optimization",
                    "https://community.snowflake.com/s/article/Checklist-Query-Performance",
                  ],
                  [
                    "Performance Optimization",
                    "https://docs.snowflake.com/en/user-guide/search-optimization-service.html",
                  ],
                  ["Quickstart Guides", "https://quickstarts.snowflake.com/"],
                  [
                    "CICD",
                    "https://medium.com/snowflake/snowpark-meets-azure-devops-to-enable-automation-of-building-data-pipelines-c5bf0fc4ff1a",
                  ],
                  [
                    "Data Vault",
                    "https://medium.com/snowflake/data-vault-recipes-edebd61ab8a6",
                  ],
                  [
                    "Data Science & Snowpark",
                    "https://medium.com/snowflake/data-vault-recipes-edebd61ab8a6",
                  ],
                  [
                    "Data Bricks Summit",
                    "https://databricks.com/dataaisummit/north-america-2021/sessions?_session_topics_na21=apache-spark-internals-and-best-practices",
                  ],
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <GridContainer>
            <GridItem xs={12} sm={12} lg={6}>
              <Card pricing>
                <CardBody pricing>
                  <h6 className={classes.cardCategory}>SMALL COMPANY</h6>
                  <div className={classes.icon}>
                    <Home className={classes.iconRose} />
                  </div>
                  <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    $29
                  </h3>
                  <p className={classes.cardDescription}>
                    This is good if your company size is between 2 and 10
                    Persons.
                  </p>
                  <Button round color="rose">
                    Choose plan
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} lg={6}>
              <Card pricing plain>
                <CardBody pricing plain>
                  <h6 className={classes.cardCategory}>Freelancer</h6>
                  <div className={classes.icon}>
                    <Icon className={classes.iconWhite}>weekend</Icon>
                  </div>
                  <h3 className={`${classes.cardTitle} ${classes.marginTop30}`}>
                    FREE
                  </h3>
                  <p className={classes.cardCategory}>
                    This is good if your company size is between 2 and 10
                    Persons.
                  </p>
                  <Button round color="white">
                    Choose plan
                  </Button>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={11}>
              <Card testimonial>
                <div className={classes.testimonialIcon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardTestimonialDescription}>
                    Your products, all the kits that I have downloaded from your
                    site and worked with are sooo cool! I love the color
                    mixtures, cards... everything. Keep up the great work!
                  </h5>
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Alec Thompson</h4>
                  <h6 className={classes.cardCategory}>@ALECTHOMPSON</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={image} alt="..." />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        </GridItem>
      </GridContainer>
    </div>
  );
}
