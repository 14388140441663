/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { Component } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "./Card";
import Headers from "./Headers.js";
import request from "./Request.js";
import { data } from "./QuestionOld.js";
import Question from "./Question.js";
import categories from "./Category.js";
import styles from "assets/css/triviastyles.css";
import { isMobile } from "react-device-detect";
import { Modal, ModalManager, Effect } from "react-dynamic-modal";
import SweetAlert from "react-bootstrap-sweetalert";

const useStyles = makeStyles(styles);

// eslint-disable-next-line no-undef
class MyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: true, device: null };
  }

  render() {
    const {
      answers,
      effect,
      onRequestClose,
      category,
      question,
      correctAnswer,
      derivedWidth,
    } = this.props;
    const ScaleUp = {
      transition: {
        // transition for the modal window
        property: "all",
        duration: 300, // millisecond
        timingfunction: "linear",
      },
      begin: {
        //beginning style of transition
        transform: "scale(0.7)",
        opacity: 0,
      },
      end: {
        // end style of transition
        transform: "scale(1)",
        opacity: 1,
      },
    };

    const customStyles = {
      content: {
        top: "auto",
        left: "auto",
        right: "auto",
        bottom: "auto",
        width: derivedWidth,
        background: "white",
        padding: "10px",
      },
    };

    const buttonStyle = {
      color: "#33a6cc",
      borderColor: "#33a6cc",
      borderRadius: "0",
    };
    return (
      <Modal
        show={false}
        onRequestClose={onRequestClose}
        effect={Effect.ScaleUp}
        style={customStyles}
      >
        <div className="modal-header">
          <h4 className="modal-title">{category}</h4>
        </div>
        <div show={false} className="modal-body">
          <h4 marginLeft={1000}>{question}</h4>
          <h4>{answers.join(", ")}</h4>
          <h4 hidden={this.state.show}>{correctAnswer}</h4>
        </div>
        <div className="modal-footer">
          &nbsp;
          <button
            type="button"
            onClick={() =>
              this.setState({
                show: false,
                // eslint-disable-next-line react/no-direct-mutation-state
                device: this.props.device == "Mobile" ? "90%" : "60%",
              })
            }
          >
            Answer
          </button>
          &nbsp;&nbsp;&nbsp;
          <button
            type="button"
            className="btn btn-primary"
            onClick={ModalManager.close}
          >
            Close
          </button>
        </div>
      </Modal>
    );
  }
}

class Trivia extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      isLoaded: null,
      categories: categories,
      data: [],
      device: null,
    };
  }
  detectDevice() {
    this.setState({ device: isMobile ? "Mobile" : "Desktop" });
  }

  handleResize(event) {
    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });
  }

  async openModal() {
    const response = await fetch("https://opentdb.com/api.php?amount=1");
    const content = await response.json();
    const answers =
      content.results[0].incorrect_answers +
      "," +
      content.results[0].correct_answer;
    const formattedAnswers = await answers.replaceAll(",", ", ");
    const formattedContent = await formattedAnswers
      .split(",")
      .sort(() => Math.random() - 0.5);
    const formattedQuestion = await content.results[0].question
      .replace("&quot;", "'")
      .replace("&#039;", "'")
      .replace("&quot;", "'");
    ModalManager.open(
      <MyModal
        answers={await formattedContent}
        category={content.results[0].category}
        question={formattedQuestion}
        correctAnswer={content.results[0].correct_answer}
        derivedWidth={this.state.device == "Mobile" ? "90%" : "60%"}
        onRequestClose={() => true}
      />
    );
  }

  async getQuestions() {
    const { categories } = this.state;
    var randomCategory =
      categories.trivia_categories[
        Math.floor(Math.random() * categories.trivia_categories.length)
      ];
    const response = await fetch("https://opentdb.com/api.php?amount=1");
    const content = await response.json();
    const answers =
      content.results[0].incorrect_answers +
      "," +
      content.results[0].correct_answer;
    const formattedAnswers = await answers.replaceAll(",", ", ");
    alert(
      content.results[0].category +
        "\n" +
        content.results[0].question +
        "\n" +
        formattedAnswers.split(",").sort(() => Math.random() - 0.5)
    );
  }

  componentDidMount() {
    this.detectDevice();
    fetch("https://opentdb.com/api_category.php")
      .then((res) => res.json())
      .then(
        (result) => {
          let rows = 0;
          data.forEach((category) => {
            if (category.questions.length > rows) {
              rows = category.questions.length;
            }
          });
          this.setState({ data: data, rows: rows, cols: data.length });
        },
        (error) => {
          this.setState({
            isLoaded: true,
            error,
          });
        }
      );
  }

  render() {
    let headerHeight = this.state.windowWidth > 640 ? 60 : 32,
      cardWidth = this.state.windowWidth / this.state.cols,
      cardHeight = (this.state.windowHeight - headerHeight) / this.state.rows,
      cards = [];

    this.state.data.forEach((category, categoryIndex) => {
      let left = categoryIndex * cardWidth;
      category.questions.forEach((question, questionIndex) => {
        cards.push(
          <Card
            left={left}
            top={questionIndex * cardHeight + headerHeight}
            height={cardHeight}
            width={cardWidth}
            question={question}
            key={categoryIndex + "-" + questionIndex}
          />
        );
      });
    });
    return (
      <div>
        <div>
          <button onClick={() => this.openModal()}>Generate Question</button>
        </div>
        <div>
          <Headers data={this.state.data} headerWidth={cardWidth} />
          {cards}
        </div>
      </div>
    );
  }
}

export default Trivia;
